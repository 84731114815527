import React from 'react';
import './power_button.scss';

class PowerButton extends React.Component {

    render(){
        return(
            <div>
            <div class="power-switch" onClick={(e) => this.props.onClick(e)}>
                <input type="checkbox" checked={this.props.power_state} />
                <div class="button">
                    <svg class="power-off">
                    <use xlinkHref="#line" class="line" />
                    <use xlinkHref="#circle" class="circle" />
                    </svg>
                    <svg class="power-on">
                    <use xlinkHref="#line" class="line" />
                    <use xlinkHref="#circle" class="circle" />
                    </svg>
                </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{display:'none'}}>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" id="line">
                    <line x1="75" y1="34" x2="75" y2="58"/>
                </symbol>
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" id="circle">
                    <circle cx="75" cy="80" r="35"/>
                </symbol>
                </svg>
            </div>
        );
    }

}

export default PowerButton;