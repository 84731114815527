import logo from './logo.svg';
import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import NoMatch from './pages/access/404Page';
import Home from './pages/home/home';

class App extends React.Component  {

  state = {
    renderReady: true,
  }

  render() {
    return (
      this.state.renderReady ?
        <Router>
          <Switch>
            <Route exact path='/' render={() => <Home/>} />
            <Route path='*' exact={true} component={NoMatch}/>;
          </Switch>
        </Router>
        : ''
    );
  }
}

export default App;
