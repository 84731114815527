import React from 'react';
import Badge from 'react-simple-badges';
import Effect from '../../controls/effects/effect';
import Footer from '../../controls/footer/footer';
import './home.scss';
import Notification from '../../controls/Notifications/notification';
import PowerButton from '../../controls/power_button/power_button';
import ReactSlider from 'react-slider';
import APIHandler from '../../pages/handler/APIHandler';
import "animate.css";

let notification = new Notification();


class Home extends React.Component {

    state = {
        active_effect: 'RGB Fade',
        power_state: true,
        brightness: 0,
        color: "FFFFFF"
    }

    componentDidMount() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        };

        //auto update
        setInterval(() => {
            fetch(APIHandler.URL + '/leds/mode', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        active_effect: data
                    })
                })
            fetch(APIHandler.URL + '/leds/mode', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        active_effect: data
                    })
                })

            fetch(APIHandler.URL + '/leds/power', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        power_state: data
                    })
                })

            fetch(APIHandler.URL + '/leds/brightness', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        brightness: data
                    })
                })

            fetch(APIHandler.URL + '/leds/color', requestOptions)
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        color: data
                    })
                })
        }, 1000);
    }

    handleEffectChange(effectName) {
        console.log(effectName);
        this.setState({
            active_effect: effectName
        }, () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            fetch(APIHandler.URL + '/leds/mode?mode=' + this.state.active_effect, requestOptions)
                .then(response => {
                    if (response.status != 200) {
                        notification.set('Server offline', 'error', 2400)
                    } else {
                        notification.set('Aktiver Effekt wurde auf ' + effectName + ' geändert!', 'instagram', 2400)
                    }
                })
        })
    }

    handleColorChange(e) {
        var colorVal = e.target.value.replace('#', '');
        this.setState({
            color: colorVal
        }, () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            var url = APIHandler.URL + '/leds/color?color=' + this.state.color
            console.log(url);

            fetch(url, requestOptions)
                .then(response => {
                    if (response.status != 200) {
                        notification.set('Server offline', 'error', 2400)
                    } else {
                        notification.set('Aktive Farbe wurde auf ' + this.state.color + ' geändert!', 'instagram', 2400)
                    }
                })
        })
    }

    handlePowerSwitch(e) {
        this.setState({
            power_state: !this.state.power_state
        }, () => {


            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            fetch(APIHandler.URL + '/leds/power/' + this.state.power_state, requestOptions)
                .then(response => {
                    if (response.status == 200) {
                        if (this.state.power_state) {
                            notification.set('LEDs eingeschaltet', 'success', 2400)
                        } else {
                            notification.set('LEDs ausgeschaltet', 'error', 2400)
                        }
                    } else {
                        notification.set('Server offline', 'error', 2400)
                    }
                })
        })

    }

    handleBrightnessChange(e) {
        this.setState({
            brightness: e.target.value
        }, () => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            fetch(APIHandler.URL + '/leds/brightness?value=' + this.state.brightness, requestOptions)
                .then(response => {
                    if (response.status != 200) {
                        notification.set('Server offline', 'error', 2400)
                    }
                })
        })
    }

    render() {
        return (
            <div>
                <div className='status-bar'>
                    <div className='animate__animated animate__bounceInLeft top-left'>
                        {this.state.power_state ? <Badge name="custom" style={{ width: 70 }} label='Aktiv' backgroundColor="#32a853" /> : <Badge style={{ width: 90 }} label='Inaktiv' backgroundColor="#cf000f" />}
                    </div>
                    <div className='animate__animated animate__bounceInRight top-right'>
                        <Badge name="custom" style={{ width: 110 }} label={this.state.active_effect} backgroundColor="#1a73e8" />
                    </div>
                </div>
                <div className='effects-container'>
                    <h2>Smart LEDs</h2>
                    <PowerButton power_state={this.state.power_state} onClick={(e) => this.handlePowerSwitch(e)} />
                    <p>Chill Area</p>
                    {this.state.power_state ?
                        <div className='animate__animated animate__pulse brightness-wrapper'>
                            <span className='brightness-label-title'>Helligkeit</span>
                            <input onChange={(e) => this.handleBrightnessChange(e)} className='brightness-slider' type="range" value={this.state.brightness} />
                            <span className='brightness-label'>{this.state.brightness}%</span>
                        </div> : ''}
                    <br />
                    <br />
                    <br />
                    <Effect onClick={(e) => this.handleEffectChange(e)} active={this.state.active_effect == "Glitzern"} effect_name='Glitzern' />
                    <Effect onClick={(e) => this.handleEffectChange(e)} active={this.state.active_effect == "Regenbogen"} effect_name='Regenbogen' />
                    <Effect onClick={(e) => this.handleEffectChange(e)} active={this.state.active_effect == "RGB Fade"} effect_name='RGB Fade' />
                    <Effect onClick={(e) => this.handleEffectChange(e)} active={this.state.active_effect == "RGB Wechsel"} effect_name='RGB Wechsel' />
                    <Effect onClick={(e) => this.handleEffectChange(e)} active={this.state.active_effect == "Einfarbig"} effect_name='Einfarbig' />
                    <div>
                        <input className='animate__animated animate__backInUp colorpicker' type="color" id="head" name="head"
                            value={'#' + this.state.color} onChange={(e) => this.handleColorChange(e, e.target.value.replace('#', ''))} />
                    </div>
                </div>
                <Notification />
            </div>
        );
    }

}

export default Home;