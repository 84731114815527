import React from 'react';
import './effect.scss';
import "animate.css";

class Effect extends React.Component {

    render(){
        return(
            <div className='animate__animated animate__fadeIn'>
                <button onClick={(e) => this.props.onClick(this.props.effect_name)} className={"glow-on-hover " + (this.props.active ? "active" : '')}>{this.props.effect_name}</button>
            </div>
        );
    }

}

export default Effect;