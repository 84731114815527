import React from 'react';
import './Access.scss';

const NoMatch = () => {
    return(
        <div className='not-found-element'>
            <h2>
                Fehler 404
            </h2>
            <center><span>Die gesuchte Seite wurde nicht gefunden.</span></center>
        </div>
    )

};

export default NoMatch;