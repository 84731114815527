import React from 'react';
import './footer.scss';
import Badge from 'react-simple-badges'

class Footer extends React.Component {

    render(){
        return(
            <div className='footer-wrapper'>
                <center><h4>Status: </h4></center>
            </div>
        );
    }

}

export default Footer;